<template>
    <td
      v-if="isCheckbox"
      :class="['table__cell table__cell--checkbox', isShow ? 'show-hover-js' : '']"
      @mouseover="handlerHover('on')"
      @mouseleave="handlerHover('off')"
    >
      <VCheckbox
        :name="'row-' + row.id"
        :id="'row-' + row.id"
        class-form="table__checkbox"
        class-label="label label-border-gray"
        :value="selectedItems.includes(row[keySelected])"
        :is-model-value="false"
        @click.stop
        @input="toggleActiveElement"
      >
        <template #default="slotProps">
          <label :for="slotProps.for" :class="slotProps.class"></label>
        </template>
      </VCheckbox>
    </td>

    <td
      :class="['table__cell table__cell--body', isShow ? 'show-hover-js' : '']"
      @mouseover="handlerHover('on')"
      @mouseleave="handlerHover('off')"
      v-for="(headCell, idx) in headRow"
      :key="String(row[headCell.value] + idx)"
    >
      <span v-if="modification.decorator">
          <component
            :class="[row.blacklist_at ? 'banned-cell' : '']"
            :is="componentName(modification.decorator?.name)"
            :row="row"
            :headCell="headCell"
          />
      </span>

      <span v-else>
        <component
          :class="[row.blacklist_at ? 'banned-cell' : '']"
          v-if="headCell?.decorator?.name"
          :is="componentName(headCell.decorator.name) +'Cell'"
          :row="row"
          :headCell="headCell"
        >
          <template #content>
            <slot name="decorator" :row="row" />
          </template>
        </component>

        <div :class="['table__cell-span--body', row.blacklist_at ? 'banned-cell' : '']" v-else>
          {{ row[headCell.value] }}
        </div>
      </span>
    </td>
</template>

<script>
import { componentName } from '@/components/ui/table/table.function'
import VCheckbox from '@/components/ui/form/Checkbox/VCheckbox'
import LinkCell from '@/components/ui/table/Decorators/VTableDecoratorLinkCell'
import ActionCell from '@/components/ui/table/Decorators/VTableDecoratorActionCell'
import TagCell from '@/components/ui/table/Decorators/VTableDecoratorTagCell'
import HideCell from '@/components/ui/table/Decorators/VTableDecoratorHideCell'
import SelectCell from '@/components/ui/table/Decorators/VTableDecoratorSelectCell'
import IconCell from '@/components/ui/table/Decorators/VTableDecoratorIconCell'
import ColorCell from '@/components/ui/table/Decorators/VTableDecoratorColorCell'
import InputCell from '@/components/ui/table/Decorators/VTableDecoratorInputCell'
import ModalCell from '@/components/ui/table/Decorators/VTableDecoratorModalCell'
import ModalParameterCell from '@/components/ui/table/Decorators/VTableDecoratorModalParameters'
import ParametersCell from '@/components/ui/table/Decorators/VTableDecoratorParameters'
import LotFavoritesCell from '@/components/ui/table/Decorators/VTableDecoratorLotFavoritesCell'
import BooleanCell from '@/components/ui/table/Decorators/VTableDecoratorBooleanCell'
import DecoratorInvert from '@/components/ui/table/Decorators/Invert/VTableDecoratorInvert'
import { ref } from 'vue'
export default {
  emits: ['active', 'decorator'],
  props: {
    headRow: {
      type: Array,
      required: true
    },
    row: {
      type: Object,
      required: true
    },
    isCheckbox: {
      type: Boolean,
      required: true
    },
    selectedItems: {
      type: Array,
      required: false
    },
    isHover: {
      type: Boolean,
      required: true
    },
    keySelected: {
      type: String,
      required: false
    },
    modification: {
      type: Object,
      required: false
    }
  },
  setup (props, { emit }) {
    const isShow = ref(false)

    const toggleActiveElement = () => {
      emit('active', props.row.id)
    }

    const handlerHover = (type) => {
      if (props.isHover) {
        type === 'on' ? isShow.value = true : isShow.value = false
      }
    }

    return {
      isShow,
      handlerHover,
      toggleActiveElement,
      componentName
    }
  },
  components: {
    VCheckbox,
    LinkCell,
    ActionCell,
    TagCell,
    ColorCell,
    IconCell,
    HideCell,
    InputCell,
    SelectCell,
    ModalCell: ModalCell,
    LotFavoritesCell,
    Invert: DecoratorInvert,
    BooleanCell,
    ModalParameterCell,
    ParametersCell
  }
}
</script>

<style lang="scss">
.banned-cell {
  opacity: .7;
  text-decoration: line-through;
}
</style>
