<template>
  <div
    :class="[
      maxWidth ? 'table-max-width' : '',  'table__container',
       scroll ? 'table__container--scroll' : '',
        isMinHeight ? 'table__container-min-h' : ''
        ]"
    ref="table"
    :style="`max-height: ${height}`"
    @scroll="handlerScroll"
  >

    <VTouchBar
      v-if="isCheckbox && selectedItems?.length && isTouchBar"
      class="mb-20"
      class-touch-bar-item="pr-30"
      :selected-items="selectedItems"
      :touch-bar-list="touchBarList"
      @action="handlerActionItems"
    />

    <div>
      <table :class="['table']" v-if="tableRows.length">
        <tr :class="['table__row table__row--head', classHead]">
          <TheTableHead
            :headRow="headRow"
            :modification="tableModification ? tableModification.headCell : {}"
            :is-checkbox="isCheckbox"
            :is-all-selected-items="getAllSelectedItems"
            :filterName="sortName"
            @active="handlerActiveAllItems"
            @filter="handlerSort"
          />
        </tr>

        <tr
          v-for="(row, index) in tableRows"
          :class="['table__row table__row--body',
          (++index % 2 || row.isGray) && !row.isWhite ? 'table__row--body-gray' : '',
          getIsLoad ? 'table__row--body-loader' : '',
          row.name && 'table__row--pointer'
          ]"
          :key="row.id"
          @click="handlerEmit({ name: 'link', item: row })"
        >
          <component
            v-if="checkModifiedRows(index)"
            :is="changeTab(checkModifiedRows(index)?.decorator?.name)"
            :head-row="headRow"
            :row="row"
          />

          <TheTableBody
            v-else
            :head-row="headRow"
            :row="row"
            :modification="tableModification ? tableModification.bodyCell : {}"
            :isHover="isHover"
            :is-checkbox="isCheckbox"
            :selected-items="selectedItems"
            :key-selected="keySelected"
            @active="handlerActiveItem"
          >
            <template #decorator="{ row }">
              <slot name="decorator" :row="row" />
            </template>
          </TheTableBody>
        </tr>
      </table>

      <div v-else-if="!getIsLoad && !tableRows.length">
        Таблица не содержит в себе элементов
      </div>

      <div class="table-load" v-if="getIsLoad">
        <SpinnerLoader />
      </div>
    </div>
  </div>

  <PaginationRefactor v-if="isPagination" @page="handlerPage" />
</template>

<script setup>
import { defineProps, defineEmits, ref, computed, defineExpose, reactive, watch, markRaw, onMounted } from 'vue'
import TheTableHead from './TheTableHead'
import TheTableBody from './TheTableBody'
import VTouchBar from '@/components/ui/touchbar/VTouchBar'
import { useStore } from 'vuex'
import SpinnerLoader from '@/components/views/Spinner/VSpinnerLoader'
import PaginationRefactor from '@/components/ui/pagination/PaginationRefactor'
import { defaultTouchBarList } from '@/utils/touchbar'
import { useGetItem } from '@/use/Base/item/useGetItem'
import { ItemController } from '@/controllers/ItemController'
import { AllTableInject } from '@/components/ui/table/Inject/AllTableInject'
// Decorators
import AllSee from '@/components/ui/table/Rows/Decorators/VTableRowDecoratorAllSee'
import { debounce } from '@/utils/utils'

const emits = defineEmits([
  'link',
  'icon-click',
  'page',
  'sort',
  'selected',
  'open-modal'
])

const props = defineProps({
  headRow: {
    type: Array,
    required: true
  },
  rows: {
    type: Array,
    required: false
  },
  urlSetRows: {
    type: String,
    required: false
  },
  urlActionQuery: {
    type: String,
    required: false
  },
  touchBarList: {
    type: Array,
    required: false,
    default: defaultTouchBarList
  },
  isTouchBar: {
    type: Boolean,
    required: false,
    default: true
  },
  scroll: {
    type: Boolean,
    required: false,
    default: true
  },
  keySelected: {
    type: String,
    required: false,
    default: 'id'
  },
  isCheckbox: {
    type: Boolean,
    required: false,
    default: true
  },
  urlAction: {
    type: String,
    required: false
  },
  transformObject: {
    type: Function,
    required: false
  },
  requestIsAuth: {
    type: Boolean,
    required: false,
    default: true
  },
  customListAction: {
    type: Object,
    required: false
  },
  isHover: {
    type: Boolean,
    required: false,
    default: true
  },
  maxWidth: {
    type: Boolean,
    required: false,
    default: true
  },
  classHead: {
    type: String,
    required: false
  },
  isMinHeight: {
    type: Boolean,
    required: false,
    default: true
  },
  sort: {
    type: String,
    required: false
  },
  injectionModificationKey: {
    type: String,
    required: false
  },
  modifiedRows: {
    type: Array,
    required: false
  },
  isPagination: {
    type: Boolean,
    required: false,
    default: true
  },
  notApi: {
    type: Boolean,
    required: false,
    default: false
  },
  height: {
    type: String,
    required: false
  }
})

let tableModification = null
if (props.injectionModificationKey) tableModification = new AllTableInject()[props.injectionModificationKey]

const selectedItems = ref([])
const table = ref([])
const store = useStore()
const itemController = new ItemController()

const getActionQuery = computed(() => {
  return props.urlActionQuery
})

watch(getActionQuery, () => {
  configuration.urlActionQuery = getActionQuery.value
})

const configuration = reactive({
  transformObject: props.transformObject,
  urlSetRows: props.urlSetRows,
  requestIsAuth: props.requestIsAuth,
  urlActionQuery: getActionQuery.value,
  sort: props.sort,
  rows: props.notApi ? props.rows : null
})

const PER_ITEM = 9

const tableRows = computed(() => {
  if (props.rows) return props.rows
  return store.getters['item/getItems']
})

const getAllSelectedItems = computed(() => {
  return tableRows.value?.length === selectedItems.value.length && selectedItems.value.length > 0
})

// Function
const changeTab = (newTab) => {
  const listTab = {
    AllSee
  }
  return markRaw(listTab[newTab])
}

const checkModifiedRows = (idx) => {
  if (!props.modifiedRows) return null
  return props.modifiedRows.find(el => el.positionIdx === idx)
}

const setStoreScroll = () => store.commit('offer/setScroll', { width: table.value.offsetWidth, scrollLeft: Math.floor(table.value.scrollLeft) })

onMounted(() => {
  setStoreScroll()
})

const handlerScroll = debounce(setStoreScroll, 10)

const handlerActiveItem = (idActiveItem) => {
  if (selectedItems.value.includes(idActiveItem)) {
    selectedItems.value = selectedItems.value.filter((id) => idActiveItem !== id)
  } else {
    selectedItems.value.push(idActiveItem)
  }

  emits('selected', selectedItems.value)
}

const handlerActiveAllItems = () => {
  if (selectedItems.value.length === tableRows.value.length) {
    selectedItems.value = []
  } else {
    tableRows.value.map((row) => {
      if (!selectedItems.value.includes(row.id)) {
        selectedItems.value.push(row.id)
      }
    })
  }
  emits('selected', selectedItems.value)
}

const handlerActionItems = async (action, items, formData = null) => {
  if (props.customListAction instanceof Map) {
    const method = props.customListAction.get(action)
    await itemController.changeItem({ url: null, action, items, custom: method, formData })
    await getItem()
  } else {
    await itemController.changeItem({ url: props.urlAction, action, items, custom: null, formData })
    await getItem()
  }
  selectedItems.value.length = 0
}

const handlerEmit = (arg) => {
  if (!arg.item.name) return
  emits(arg.name, arg)
}

const { handlerPage, handlerSort, getIsLoad, sortName, getItem } = useGetItem(emits, PER_ITEM, configuration)

defineExpose({
  selectedItems,
  getAllSelectedItems,
  handlerActiveAllItems,
  handlerActiveItem
})
</script>
